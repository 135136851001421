import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import { Job } from "../../types/jobs";
import { theme } from "../theme";
import moment from "moment";
import React from "react";
import { CommentIcon } from "../icons";
import { useHistory } from "react-router-dom";
import { MY_MESSAGES_ROUTE } from "../../constants/routes";
import { useChatActive } from "../../hooks/use-chat-active";
import { useUnseenMessages } from "../../hooks/use-unseen-messages";
import { NotificationBadge } from "./notification-badge";

interface JobChatListItem {
  job: Job;
}

export const JobChatListItem: React.FC<JobChatListItem> = ({ job }) => {
  const history = useHistory();
  const isChatActive = useChatActive(job);
  const unseenCount = useUnseenMessages(job.id!);
  return (
    <MessageItem isActive={isChatActive} key={job.id} onClick={() => history.push(`${MY_MESSAGES_ROUTE}/${job.id}`)}>
      <MessageDate>{moment.unix(job.date as any).format("MM/DD")}</MessageDate>
      <MessagePractitioner>{job.patientDisplayName}</MessagePractitioner>
      <MessageButton>
        <div style={{ position: "relative" }}>
          <CommentIcon />
          {unseenCount > 0 && (
            <div style={{ position: "absolute", top: "-10px", left: "10px" }}>
              <NotificationBadge count={unseenCount} />
            </div>
          )}
        </div>
      </MessageButton>
    </MessageItem>
  );
};

interface MessageItemProps {
  isActive: boolean;
}
const MessageItem = styled.div<MessageItemProps>`
  background-color: ${theme.colors.jellyBean};
  border-radius: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 40px;
  overflow: hidden;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;

const MessageDate = styled.div`
  border-right: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 20px;
`;

const MessagePractitioner = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 20px;
`;

const MessageButton = styled.div`
  background-color: ${theme.colors.lightGreen};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;
