import React, {useEffect, useState} from "react";
import moment from "moment";
import styled from "styled-components";
import {CHATS_COLLECTION, JOBS_COLLECTION} from "../../constants/collections";
import {firestore} from "../../firebase";
import {usePatientAuth} from "../../hooks/use-patient-auth";
import {MainContentWrapper} from "../layout";
import {PageLoading} from "../loading";
import useScrollTop from "../../hooks/use-scroll-to-top";
import {Spacer3} from "../spacers";
import {ArrowLeftIcon, CommentIcon, PaperIcon} from "../icons";
import {theme} from "../theme";
import {useHistory} from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import {Job, JobStatus} from "../../types/jobs";
import {useChatActive} from "../../hooks/use-chat-active";

interface JobChatProps {
  jobId: string;
  showBackLink?: boolean;
}

const JobChat: React.FC<JobChatProps> = ({ jobId, showBackLink = true }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState<Job>();
  const { user, token } = usePatientAuth();
  const history = useHistory();
  const isChatActive = useChatActive(job!);
  useScrollTop();

  const markMessagesAsSeen = async (jobId: string) => {
    if (!user || !user?.firebaseUser) return;
    const jobDocRef = firestore.collection(JOBS_COLLECTION).doc(jobId);
    const jobDoc = await jobDocRef.get();
    const jobData = jobDoc.data();

    if (!jobDoc.exists || !jobData?.confirmedApplicant) return;

    const chatsCollectionRef = jobDocRef
      .collection(CHATS_COLLECTION)
      .where("senderId", "==", jobData?.confirmedApplicant)
      .where("seen", "==", false);

    const chatsSnapshot = await chatsCollectionRef.get();
    const batch = firestore.batch();

    chatsSnapshot.docs.forEach((doc) => {
      batch.update(doc.ref, { seen: true });
    });

    await batch.commit();
  };

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchJobAndChats = async () => {
      setLoading(true);

      try {
        const jobDocRef = firestore.collection(JOBS_COLLECTION).doc(jobId);

        // Fetch job metadata
        const jobDoc = await jobDocRef.get();
        if (!jobDoc.exists) {
          console.error("Job not found!");
          setLoading(false);
          return;
        }
        const jobData = { id: jobDoc.id, ...jobDoc.data() };

        // Fetch chat messages and listen for updates
        unsubscribe = jobDocRef
          .collection(CHATS_COLLECTION)
          .orderBy("createdAt", "asc")
          .onSnapshot(async (snapshot: any) => {
            const chats = snapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Set both job metadata and messages
            setJob(jobData as Job);
            setMessages(chats);
            setLoading(false);

            // Mark messages as seen
            await markMessagesAsSeen(jobId);
          });
      } catch (error) {
        console.error("Error fetching job or chats:", error);
        setLoading(false);
      }
    };

    fetchJobAndChats();

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    if (!user || !user?.firebaseUser) return;

    await firestore
      .collection("jobs")
      .doc(jobId)
      .collection("chats")
      .add({
        text: newMessage,
        senderId: user?.firebaseUser?.uid,
        senderDisplayName: user?.firebaseUser?.displayName ?? user?.firebaseUser?.email,
        createdAt: new Date(),
        seen: false,
      });

    setNewMessage("");
  };

  if (loading || !user || !user?.firebaseUser) {
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );
  }

  return (
    <>
      {showBackLink && (
        <>
          <BackLink onClick={() => history.goBack()}>
            <ArrowLeftIcon />
            Back
          </BackLink>
          <Spacer3 />
        </>
      )}
      <ChatContainer>
        <ChatHeader isActive={isChatActive}>
          <div className="my-message-wrapper">
            <CommentIcon />
            My Message
          </div>
          {job?.patientDisplayName}
          <div style={{ minWidth: 100 }}></div>
        </ChatHeader>
        <MessagesList>
          {!isChatActive && !token?.claims.admin && (
            <ChatAlert>
              {job?.status === JobStatus.PENDING ? (
                <p>This chat will be enabled once the job is confirmed</p>
              ) : (
                <p>
                  This chat was closed. If you have any doubt or need to speak to someone please{" "}
                  <a href="mailto:info@caregivergo.net">contact us</a>
                </p>
              )}
            </ChatAlert>
          )}
          <div style={{ padding: 20 }}>
            {messages.map((msg: any) => (
              <MessageItem key={msg.id} isUser={msg.senderId === user?.firebaseUser?.uid}>
                <MessageText isUser={msg.senderId === user?.firebaseUser?.uid}>{msg.text}</MessageText>
                <MessageTime>{moment(msg.timestamp?.toDate()).format("MM/DD")}</MessageTime>
              </MessageItem>
            ))}
          </div>
        </MessagesList>
        <ChatInputContainer>
          <ChatInput
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            disabled={!isChatActive}
          />
          <SendButton onClick={handleSendMessage} disabled={!isChatActive}>
            <PaperIcon />
          </SendButton>
        </ChatInputContainer>
      </ChatContainer>
      <Spacer3 />
    </>
  );
};

export default JobChat;
const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 600px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
`;

interface ChatHeaderProps {
  isActive: boolean;
}

const ChatHeader = styled.div<ChatHeaderProps>`
  background-color: ${({ isActive }) => (isActive ? theme.colors.lightGreen : theme.colors.alto)};
  padding: 10px 20px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .my-message-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

const MessagesList = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 500px;
  position: relative;
`;

interface MessageProps {
  isUser: boolean;
}

const MessageItem = styled.div<MessageProps>`
  display: flex;
  justify-content: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
  margin-bottom: 10px;
  gap: 10px;
`;

const MessageText = styled.div<MessageProps>`
  background-color: ${({ isUser }) => (isUser ? theme.colors.aquaGreen : theme.colors.alabaster)};
  color: #000;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
`;

const MessageTime = styled.div`
  font-size: 12px;
  color: #999;
  margin-top: 5px;
`;

const ChatInputContainer = styled.div`
  background-color: ${theme.colors.cloudGray};
  display: flex;
  padding: 10px;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;

  &:disabled {
    cursor: not-allowed;
  }
`;

const SendButton = styled.button`
  background-color: ${theme.colors.jellyBean};
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;

  &:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
  }
`;

const BackLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const ChatAlert = styled.div`
  display: flex;
  background-color: ${theme.colors.paleRose};
  color: ${theme.colors.wineRed};
  font-weight: bold;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  height: 80px;

  p {
    margin: 0;

    a {
      color: ${theme.colors.wineRed};
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
